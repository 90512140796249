import React from "react";
import Select from "react-select";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'rgba(209, 213, 219, 0.9)', // Initial border color
        boxShadow: state.isFocused ? '0 0 0 0px #ebac78' : 'none', // Box shadow on focus
        '&:hover': {
            borderColor: '#ebac78', // Hover border color
        },

    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'gray-200', // Change dropdown indicator color if needed
    }),

    clearIndicator: (provided) => ({
        ...provided,
        color: 'black', // Change clear indicator color if needed
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black', // Change selected value text color if needed

    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ebac78' : state.isFocused ? '#f3e5d7' : 'white', // Background color on select and focus
        color: state.isSelected ? 'white' : 'black', // Text color on select and focus
        '&:hover': {
            backgroundColor: state.isSelected ? '#ebac78' : '#f3e5d7', // Hover background color
        },
    }),
};


const AddTag = () => {
    const options = [
        { value: 'home', label: 'Home' },
        { value: 'new-in', label: 'New In' },
        { value: 'designers', label: 'Designers' },
        { value: 'women-category', label: 'Women Category' },
        { value: 'men-category', label: 'Men Category' },
        { value: 'brand', label: 'Brand' },
        { value: 'collaboration', label: 'Brand Collaboration' },
        { value: 'rakhi-curation', label: 'Rakhi Curation' },
      ]

    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add Tag</h1>
            </div>
            <div className="py-5 flex flex-col gap-5 ">

               
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm">
                <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Page List
                        </label>
                        <div>
                            <Select
                            options={options}
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                name="userStatus"
                                className="basic-single sm:text-sm"
                                styles={customStyles}
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Title
                        </label>
                        <div className="">
                            <input
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Meta Title"
                            />

                        </div>
                    </div>
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta keywords
                        </label>
                        <div className="">
                            <input
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Meta keywords"
                            />

                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Description
                        </label>
                        <div className="">
                            <textarea
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Meta Description"
                            />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddTag