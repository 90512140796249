import React, { useEffect, useState, useCallback } from "react";
import Attributes from "./Attributes";
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toast";
import { BASEURL, IMAGEURL, VIDEOURL } from "../../../helper/constant.js";
import UploadFileComp from "../../../components/UploadFileComp";
import ProgressBar from "../../../components/ProgressBar.jsx";
import Spinner from "../../../components/Spinner.jsx";
import { productSchema, product_schema } from "../../../schema/index.jsx";
import { object } from "yup";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'rgba(209, 213, 219, 0.9)', // Initial border color
        boxShadow: state.isFocused ? '0 0 0 0px #ebac78' : 'none', // Box shadow on focus
        '&:hover': {
            borderColor: '#ebac78', // Hover border color
        },

    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'gray-200', // Change dropdown indicator color if needed
    }),

    clearIndicator: (provided) => ({
        ...provided,
        color: 'black', // Change clear indicator color if needed
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black', // Change selected value text color if needed

    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ebac78' : state.isFocused ? '#f3e5d7' : 'white', // Background color on select and focus
        color: state.isSelected ? 'white' : 'black', // Text color on select and focus
        '&:hover': {
            backgroundColor: state.isSelected ? '#ebac78' : '#f3e5d7', // Hover background color
        },
    }),
};

export function debounce(func, delay) {
    let timeoutId;
    function debounced(...args) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    }

    debounced.cancel = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    };

    return debounced;
}

const AddProduct = () => {

    const [categories, setCategories] = useState([])
    const [brandData, setBrandData] = useState([])
    const [attributes, setAttributes] = useState([])
    const [productImage, setProductImage] = useState("")
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [statusOption, setStatusOption] = useState(null)
    const [colorValue, setColorValue] = useState({})
    const [variationCheck, setVariationCheck] = useState([])
    const [attributeData, setAttributeData] = useState([])
    const [progress, setProgress] = useState(0);
    const [singleImageProgress, setSingleImageProgress] = useState(0)
    const [mulImageProgress, setMulImageProgress] = useState(0)
    const [singleImage, setSingleImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(true)
    const [multipleImages, setMultipleImages] = useState([]);
    const [totalCount, setTotalCount] = useState(0)
    const [defaultUrl, setDefaultUrl] = useState("https://revamp-revivify.enpointe.io/")
    const [permaString, setPermaString] = useState("")
    const [editProduct, setEditProduct] = useState([])
    const [selectAttribute, setSelectAttribute] = useState([]);
    const [variationData, setVariationData] = useState([])
    let [attributesOption, setAttributesOption] = useState([]);
    const [parentAttribute, setParentAttribute] = useState([]);
    const [selectChildValue, setSelectChildValue] = useState({});
    const [childInputValue, setChildInputValue] = useState([]);
    const [loopVariation, setLoopVariation] = useState([]);
    const [metaImage, SetMetaIMage] = useState(null);
    let [disableSku,setDisableSku] = useState({
        checked:false,
        errorName :"SKU Exits"
    })
    const [selectProductOption, setSelectProductOption] = useState({
        value: 1,
        label: "Simple Product"
    },)
    const [error, setError] = useState({ statusError: "", productError: "" });

    const [valid, setValid] = useState(false)
    const navigate = useNavigate();
    const { product_id } = useParams();
    console.log("productId", product_id)
    const initialFormState = selectChildValue?.keystatic?.flatMap((item, parentIndex) =>
        item?.data?.map((dd, childIndex) => ({
            price: 0,
            stock: 0,
            description: '',
            parentIndex,
            childIndex,
            childAttributeId: dd.p_attribute_value_id

        }))
    ) || [];


    const [variationForm, setVariationForm] = useState([]);
    const [fullUrl, setFullUrl] = useState('');
    const formValues =
    {
        p_product_name: "",
        parmalink: "",
        p_product_desc: "",
        p_product_short_desc: "",
        p_original_purchase_price: 0,
        p_meta_title: "",
        p_meta_desc: "",
        p_meta_tags: "",
        p_gen_regular_price: 0,

        p_gen_sale_price: 0,

        p_inv_sku: "",

        p_inv_manage_stock: false,
        p_inv_stock_quantity: 0,
        p_inv_sold_individually: false,
        p_var_regular_price: 0,
        p_var_description: "",
        p_var_stock_quantity: 0,
        p_product_schedule_date: ""
    }

    const [initialValues, setInitialValues] = useState(formValues)
    const [permaLink, setPermaLink] = useState({
        category: "",
        shortDescription: "",
        designer: "",
        skuValue: ""
    })


    useEffect(() => {

        const fetchData = async () => {
            await getCategories();
            await getBrands();
            await getAttributes();
        }
        fetchData()
    }, [])


    useEffect(() => {
        if (categories?.length > 0 && brandData?.length > 0 && attributes?.length > 0) {
            setLoading(false);
        }
    }, [categories, brandData, attributes]);


    const statusDataOption = [
        {
            value: 0,
            label: "Draft"
        },
        {
            value: 1,
            label: "Publish"
        },
        {
            value: 2,
            label: "Schedule"
        }
    ]
    const ProductSelectOption = [
        {
            value: 1,
            label: "Simple Product"
        },
        {
            value: 2,
            label: "Variation Product"
        },
    ]
    const getCategories = async () => {
        const response = await MasterServices.getAllCategory();
        console.log("getCategories", response)
        if (response?.data?.data?.length > 0) {
            setCategories(response?.data?.data)
        } else {
            setCategories([])
        }
    }
    const getBrands = async () => {
        const response = await MasterServices.getAllBrands();
        console.log("getBrands", response)
        if (response?.data?.data?.length > 0) {
            setBrandData(response?.data?.data)
        } else {
            setBrandData([])
        }
    }

    const getAttributes = async () => {
        const response = await MasterServices.getAllAttributes();
        console.log("getAttributes", response)
        if (response?.data?.data?.length > 0) {
            setAttributes(response?.data?.data)
            let attributesOpt = response?.data?.data?.length > 0 && response?.data?.data.map((ele, index) => ({
                ...ele,
                value: ele?.p_product_attribute_id,
                label: ele?.p_product_attribute_name
            }));
            setAttributesOption(attributesOpt)

        } else {
            setAttributes([])
        }
    }


    const handleRemoveImage = (indexToRemove) => {
        // e.preventDefault();
        setMultipleImages(prevImages => {
          
            const updatedImages = prevImages.filter((_, index) => index !== indexToRemove);
            
            return updatedImages;
        });
    };

    const handleSingleImageUpload = async (event, setImageState) => {
        const file = event.target.files[0];
        if (file) {
            
            await UploadFileComp(file, setImageState, setSingleImageProgress);
        }
        event.target.value = ''; // Clear the file input
    };

    const handleMetaImageUpload = async (event, setImageState) => {
        const file = event.target.files[0];
        if (file) {

            await UploadFileComp(file, setImageState, setSingleImageProgress);
        }
        event.target.value = ''; // Clear the file input
    }
    const handleVideoUpload = async (event, setVideoState) => {
        const file = event.target.files[0];
        if (file) {
            
            await UploadFileComp(file, setVideoState, setProgress);
        }
        event.target.value = ''; // Clear the file input
    };

    const handleMultipleImagesUpload = async (event, setImagesState) => {
        const files = event.target.files;
        if (files.length > 0) {
            let uploadedFiles = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                await UploadFileComp(file, (fileName) => {
                    if (fileName) {
                        console.log("UploadFileComp", fileName)
                        uploadedFiles.push(fileName);
                    }

                }, setMulImageProgress);
                
            }
            setImagesState((prevState) => [...prevState, ...uploadedFiles]);
        }
        event.target.value = ''; 
    };

    const getSingleProduct = async (id) => {
        try {

            const response = await MasterServices.getProductById(id);
            console.log("getSingleProduct", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setEditProduct(response?.data?.data)
            }
        }
        catch (error) {
            toast.error(error)
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            if (product_id) {
                await getSingleProduct(product_id)
            }
        }
        fetchData()
    }, [product_id])


    useEffect(() => {

        if (editProduct?.length > 0) {
            editProduct.forEach((item) => {
                setInitialValues({
                    p_product_name: item?.p_product_name,
                    p_product_desc: item?.p_product_desc,
                    p_product_short_desc: item?.p_product_short_desc,
                    p_original_purchase_price: item?.p_product_eep_price,
                    p_meta_title: item?.p_meta_title,
                    p_meta_desc: item?.p_meta_desc,
                    p_meta_tags: item?.p_meta_tags,
                    p_gen_regular_price: item?.p_product_price,
                    p_gen_sale_price: item?.p_sale_price,
                    p_inv_sku: item?.p_product_sku,
                    p_inv_manage_stock: item?.p_in_stok === "Y" ? true : false,
                    p_inv_stock_quantity: item?.p_stok_quantity,
                    p_inv_sold_individually: item?.p_product_is_sold_individually === "Y" ? true : false,
                    p_var_regular_price: 0,
                    p_var_sale_price: 0,
                    p_var_stock_quantity: 0,
                    p_product_schedule_date: item?.p_product_schedule_dates ? item?.p_product_schedule_dates : null
                })

                if (item?.p_product_parmalink) {
                    setFullUrl('')
                }

                if (ProductSelectOption.some(ele => ele.value === item?.p_product_type_id)) {

                    setSelectProductOption({
                        value: item?.p_product_type_id,
                        label: item?.p_product_type_id === 2 ? "Variation Product" : "Simple Product",
                    })
                }

                setVideo(item?.p_product_video)

                if (item?.p_image_gallery) {
                    const imageGallery = item?.p_image_gallery.split(',');
                    console.log("imageGallery", imageGallery)
                    setMultipleImages(imageGallery)
                }

                if (item?.p_product_image1) {
                    const editimage = item?.p_product_image1.split('images/')
                    setSingleImage(editimage[1])

                }

                if (item?.m_designers_id) {

                    setSelectedBrand((prev) => ({
                        ...prev,
                        brandID: parseInt(item?.m_designers_id)
                    }))
                }
                if (statusDataOption.some(ele => ele.label === item?.p_porducts_status)) {
                    setStatusOption((prev) => ({
                        ...prev,
                        label: item?.p_porducts_status
                    }))
                }


                let newColorValue = []
                if (item?.p_product_attributes) {
                    const attData = JSON.parse(item?.p_product_attributes);
                    console.log("attData", attData)
                    let newAttributes = [];
                    let newOptions = []

                    if (attributesOption?.length > 0) {
                        let inputChildValue = []
                        attributesOption.forEach((ele, index) => {

                            if (attData.some(aa => aa?.p_attribute_key_id !== ele?.p_product_attribute_id)) {
                                newOptions.push({
                                    ...ele,
                                    id: ele?.p_product_attribute_id,
                                    label: ele?.p_product_attribute_name
                                });
                            }

                            if (attData.some(aa => aa?.p_attribute_key_id === ele?.p_product_attribute_id)) {
                                newAttributes.push({
                                    ...ele,
                                    id: ele?.p_product_attribute_id,
                                    label: ele?.p_product_attribute_name
                                });
                            }

                            if (ele?.p_child_attributes?.length > 0) {
                                //console.log("pppp",ele)
                                ele?.p_child_attributes.forEach((pc) => {
                                    attData.forEach((aa) => {

                                        aa?.data?.forEach((pp) => {
                                            if (pp?.p_child_attribute_id === pc?.p_child_attribute_id) {

                                                inputChildValue.push({
                                                    childId: pc?.p_child_attribute_id,
                                                    inputValue: pp?.inputValue
                                                })
                                            }
                                        })
                                    })
                                })
                            }

                        })

                        setChildInputValue(inputChildValue)
                        console.log("inputChildValue", inputChildValue)
                    }



                    if (attData?.length > 0) {
                        let varCheck = [];

                        attData.forEach((ele, ind) => {

                            newColorValue.push({
                                p_product_attribute_id: ele?.p_attribute_key_id,
                                data: ele?.data,
                                parentIndex: ind
                            })

                            varCheck.push({
                                checked: ele?.variationCheck,
                                parentId: ele?.p_attribute_key_id
                            })

                        })
                        console.log("varCheck", varCheck)
                        setVariationCheck(varCheck)
                    }


                    setSelectChildValue({
                        keystatic: newColorValue
                    })
                    setAttributesOption(newOptions)
                    setParentAttribute(newAttributes);

                }

                if (item?.p_category_id || item?.p_sub_category_id) {
                    const categoryIds = item.p_category_id.split(";") || [];
                    const subCategoryIds = item?.p_sub_category_id?.split(";") || [];
                    let updatedCat = [];

                    if (Array.isArray(categories)) {
                        categories.forEach((ele) => {
                            if (categoryIds.includes(ele?.p_category_name)) {
                                let subcat = [];
                                if (Array.isArray(ele?.sub_category)) {
                                    ele.sub_category.forEach((sub) => {
                                        if (subCategoryIds.includes(sub?.cat_name)) {
                                            subcat.push({
                                                subcatname: sub?.cat_name,
                                                ischecked: true,
                                            });
                                        }
                                    });
                                }
                                updatedCat.push({
                                    catname: ele?.p_category_name,
                                    ischecked: true,
                                    subcat: subcat,
                                });
                            }
                        });
                    }
                    console.log("updatedCat", updatedCat)
                    setSelectedCategories(updatedCat)
                }

                if (item?.p_variation_product) {
                    if (item?.p_variation_product?.length > 0) {
                        console.log("p_variation_product", item?.p_variation_product)
                        //setLoopVariation(item?.p_variation_product)
                        const filterData = item?.p_variation_product?.length > 0 && item?.p_variation_product.map((ele) => {
                            return {
                                price: parseInt(ele?.price),
                                stock: parseInt(ele?.price),
                                description: ele?.description,
                                p_product_attribute_id: parseInt(ele?.p_product_attribute_id),
                                childAttributeId: parseInt(ele?.p_attribute_value_id),
                                childAttributeName: ele?.childAttribute

                            }

                        })
                        console.log("filterDatjaya", filterData)

                        setVariationForm(filterData)
                        setVariationData(filterData)
                    } else {
                        setVariationForm([]);
                        setVariationData([])
                    }

                }


            })
        }

    }, [editProduct, attributes])


    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        //validationSchema: productSchema,

        onSubmit: async (values) => {

            const body = {
                ...values,
                parmalink: fullUrl ? fullUrl : "",
                p_product_image: singleImage ? singleImage : null,
                p_product_category: selectedCategories?.length > 0 ? selectedCategories : [],
                p_product_designer: selectedBrand ? selectedBrand?.brandID : null,
                p_product_status: statusOption?.label ? statusOption : null,
                p_image_gallery: multipleImages ? multipleImages : [],
                p_product_video: video ? video : null,
                p_product_type: selectProductOption ? selectProductOption : null,
                p_product_attributes: attributeData?.length > 0 ? attributeData : [],
                p_variation_product: variationData?.length > 0 ? variationData : [],
                p_meta_image: metaImage ? metaImage : null
            }


            console.log("bodyyyyyyy", body)
            if (editProduct?.length > 0 && product_id) {
                editProductSubmit(body)
            } else {
                addProductSubmit(body)
            }

        }
    })

    const addProductSubmit = async (body) => {
        try {
            const response = await MasterServices.addProducts(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                toast.success("Product Added Successfully");
                navigate("/product-page")
                clearForm();
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error("An error occurred while adding the product");
        }

    }

    const editProductSubmit = async (body) => {

        let data = body;
        data = { ...data, porduct_id: product_id }


        try {
            const response = await MasterServices.editProducts(data);
            console.log("body", response)
            if (response?.data?.success === true) {
                toast.success("Product Edited Successfully");
                navigate("/product-page")
                clearForm();
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error("An error occurred while Edited the product");
        }

    }

    const handleCategoryChange = (category, isChecked, id) => {

        setSelectedCategories(prevState => {
            let updatedCategories = [...prevState];

            if (isChecked) {
                const existingCategory = updatedCategories.find(cat => cat.catname === category);
                if (!existingCategory) {
                    updatedCategories.push({
                        catname: category,
                        catid: id,
                        ischecked: true,
                        subcat: []
                    });
                }
            } else {
                updatedCategories = updatedCategories.filter(cat => cat.catname !== category);
            }

            return updatedCategories;
        });

    };

    const handleSubCategoryChange = (category, subCategory, isChecked, subID) => {

        setSelectedCategories(prevState => {
            let updatedCategories = [...prevState];

            let categoryIndex = updatedCategories.findIndex(cat => cat.catname === category);

            if (categoryIndex === -1) {
                updatedCategories.push({
                    catname: category,
                    catid: null,
                    ischecked: false,
                    subcat: []
                });
                categoryIndex = updatedCategories.length - 1;
            }

            let updatedCategory = { ...updatedCategories[categoryIndex] };

            let updatedSubCategories = updatedCategory.subcat.filter(sub => sub.subcatname !== subCategory);

            if (isChecked) {
                updatedSubCategories.push({
                    subcatname: subCategory,
                    subcatid: subID,
                    ischecked: true
                });
            }

            updatedCategory.subcat = updatedSubCategories;
            updatedCategory.ischecked = updatedSubCategories.length > 0 ? true : true;

            updatedCategories[categoryIndex] = updatedCategory;

            return updatedCategories;
        });
    };

    const handleBrandChange = async (brandname, isChecked, brandID) => {

        if (isChecked) {
            // Update state with the selected brand's static format
            const newState = {
                brandname: brandname,
                brandID: brandID
            };
            setSelectedBrand(newState);
            // if (brandID) {
            //     await getDesginerProductCountID(brandID)
            // }


        } else {
            // If unchecked, reset the state to an empty object
            setSelectedBrand({});
        }
    }

    const clearForm = () => {
        navigate("/product-page")
    }


    const checkSkuValueHandler = async (permaSkuValue) => {
        setLoading(true)
        try {
            const response = await MasterServices.checkSkuValue(permaSkuValue);
            console.log("checkSkuValueHandler", response)
            
            if(response?.data?.data?.length>0 && response?.data?.message==="SKU Exists"){
                toast.error(response?.data?.message)
                setLoading(false)
                setDisableSku((prev=>({
                    ...prev,
                    checked:true
                })))
              
            }else{
                setLoading(false)
                setDisableSku((prev=>({
                    ...prev,
                    checked:false
                })))
            }
        }
        catch (error) {
            console.log("checkSkuValueHandler", error)
        }
    }
    const transformString = (str) => {
        return str
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9-]/g, '');
    }

    const transformSku = (str) => {
        return str.toUpperCase().replace(/\s+/g, '-').replace(/[^A-Z0-9-]/g, '');
    }

    useEffect(() => {
        if (selectedBrand) {
            setPermaLink((prev) => ({
                ...prev,
                designer: selectedBrand?.brandname ? transformString(selectedBrand?.brandname) : ""
            }))
        }
        if (selectedCategories?.length > 0) {
            selectedCategories.forEach((ele, index) => {
                if (index === 0) {
                    setPermaLink(prev => ({
                        ...prev,
                        category: ele?.catname ? transformString(ele?.catname) : '',
                    }))
                }
            })
        }
        if (selectedCategories?.length <= 0) {
            setPermaLink(prev => ({
                ...prev,
                category: '',
            }))
        }

    }, [selectedCategories, selectedBrand])

    useEffect(() => {
        const parts = [];
        if (permaLink.category) {
            parts.push(transformString(permaLink.category));
        }
        if (permaLink.designer) {
            parts.push(transformString(permaLink.designer));
        }
        if ((permaLink.shortDescription && (permaLink.skuValue && disableSku.checked===false))) {
            parts.push(`${transformString(permaLink.shortDescription)}-${transformSku(permaLink.skuValue)}`);
        } else if (permaLink.skuValue && disableSku.checked===false) {
            
                parts.push(transformSku(permaLink.skuValue));
            
        } else if (permaLink.shortDescription) {
            parts.push(transformString(permaLink.shortDescription));
        }


        const newUrl = `${defaultUrl}${parts.join('/')}`;
        setFullUrl(newUrl);


    }, [permaLink,disableSku]);

    const fetchData = useCallback(debounce(async () => {
        if (permaLink.skuValue) {
            const permaSkuValue = transformSku(permaLink.skuValue);
            if (permaSkuValue) {

                await checkSkuValueHandler(permaSkuValue);
            }
        }
    }, 1000), [permaLink.skuValue]);

    useEffect(() => {
        fetchData();
        return () => {
            fetchData.cancel();
        };
    }, [permaLink.skuValue, fetchData]);



    

    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">{product_id ? "Edit Product" : "Add Product"}</h1>
            </div>

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                    //submitForm(e)
                }}
                className="py-5">

                {/* product name,permalink desc */}
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  gap-5 bg-white p-5 rounded-lg">
                    <div className="md:col-span-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Product Name
                        </label>
                        <div className="">
                            <input
                                value={values.p_product_name}
                                onChange={(event) => {
                                    handleChange(event);
                                }}
                                onBlur={handleBlur}
                                type="text"
                                name="p_product_name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm   placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Product Name"
                            />

                        </div>
                        {errors.p_product_name && touched.p_product_name ? (
                            <p className="text-red-600 text-sm">
                                {errors.p_product_name}
                            </p>
                        ) : null}
                    </div>
                    <div className="md:col-span-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Short Description
                        </label>
                        <div className="">
                            <textarea
                                value={values.p_product_short_desc}
                                onChange={(event) => {
                                    handleChange(event);
                                    setPermaLink((prev) => ({
                                        ...prev,
                                        shortDescription: transformString(event?.target?.value)
                                    }))
                                }}
                                onBlur={handleBlur}
                                type="text"
                                name="p_product_short_desc"
                                className="block w-full h-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Short Description"
                            />

                        </div>
                        {errors.p_product_short_desc && touched.p_product_short_desc ? (
                            <p className="text-red-600 text-sm">
                                {errors.p_product_short_desc}
                            </p>
                        ) : null}
                    </div>
                    <div className="md:col-span-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Sku value
                        </label>
                        <div className="">
                            <input
                                value={values.p_inv_sku}
                                onChange={(event) => {
                                    handleChange(event);
                                    setPermaLink((prev) => ({
                                        ...prev,
                                        skuValue: event.target.value
                                    }))
                                    if(!event.target.value){
                                        setDisableSku((prev)=>({
                                            ...prev,
                                            checked:false
                                        }))
                                    }
                                }}
                                onBlur={handleBlur}
                                type="text"
                                name="p_inv_sku"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm   placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="SKU Value"
                            />
                            {
                                disableSku.checked ===true&&
                                (
                                    <p className="text-sm text-red-500">{disableSku.errorName}</p>
                                )
            
                            }

                        </div>

                    </div>
                    <div className="md:col-span-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Permalink
                        </label>
                        <div className="flex items-center  rounded-md border-0  text-gray-900 shadow-sm w-full ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 ">
                            <label className="px-2 py-2  text-black rounded-md  text-sm bg-slate-100 w-full border-2">{fullUrl}</label>
                            {/* <input type="text"
                                value={values.parmalink}
                                onChange={(event) => {
                                    handleChange(event);
                                }}
                                onBlur={handleBlur}
                                name="parmalink"
                                className="p-2 flex-1 overflow-hidden border-0 rounded-r-md sm:text-sm placeholder:text-gray-400  ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78] " 
                            /> */}
                        </div>
                    </div>

                    <div className="md:col-span-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Long Description
                        </label>
                        <div className="">
                            <textarea
                                value={values.p_product_desc}
                                onChange={(event) => {
                                    handleChange(event);
                                }}
                                onBlur={handleBlur}
                                type="text"
                                name="p_product_desc"
                                className="block w-full h-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Long Description"
                            />

                        </div>
                    </div>

                </div>
                <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700"></hr>

                {/* {attributes & gallery,video, images category} */}
                <div className="flex flex-col md:flex-row gap-4 my-5">
                    <div className="md:basis-3/4">
                        {/* attributes */}
                        <div className="">
                            <Attributes
                                attributes={attributes}
                                selectProductOption={selectProductOption}
                                setSelectProductOption={setSelectProductOption}
                                ProductSelectOption={ProductSelectOption}
                                values={values}
                                handleChange={handleChange}
                                colorValue={colorValue}
                                setColorValue={setColorValue}
                                setVariationCheck={setVariationCheck}
                                variationCheck={variationCheck}
                                setAttributeData={setAttributeData}
                                attributeData={attributeData}
                                error={error}
                                setError={setError}
                                setSelectAttribute={setSelectAttribute}
                                selectAttribute={selectAttribute}
                                attributesOption={attributesOption}
                                setVariationData={setVariationData}
                                variationData={variationData}
                                setParentAttribute={setParentAttribute}
                                parentAttribute={parentAttribute}
                                selectChildValue={selectChildValue}
                                setSelectChildValue={setSelectChildValue}
                                setChildInputValue={setChildInputValue}
                                childInputValue={childInputValue}
                                setLoopVariation={setLoopVariation}
                                loopVariation={loopVariation}
                                setVariationForm={setVariationForm}
                                variationForm={variationForm}
                                initialFormState={initialFormState}
                                setLoading={setLoading}
                                loading={loading}

                            />
                        </div>
                        {/* product gallery */}
                        <div className="bg-white rounded-lg my-5 ">
                            <div className="">
                                <h4 className="text-md text-black font-semibold p-4">Product Gallery</h4>
                                <div className="border-[1px] border-t-[#ebac78]  h-[300px] overflow-auto">
                                    <div className="flex items-center  p-4 justify-center h-full ">
                                        {
                                            multipleImages?.length > 0 ?
                                                <div className="w-full h-full rounded-lg border-2 border-dashed border-gray-400 bg-gray-200  text-white p-5 overflow-auto">
                                                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-4">
                                                        {
                                                            multipleImages.map((img, index) => {
                                                                // const imageUrl = URL.createObjectURL(ele);
                                                                return (
                                                                    <>

                                                                        <div key={index} className="relative">
                                                                            <div className="border border-black w-[150px] h-[200px] rounded-lg overflow-hidden relative">
                                                                                <img src={`${IMAGEURL}${img}`} className="absolute top-0 left-0 w-full h-full object-cover opacity-25" alt="name" />
                                                                                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                                                    <button
                                                                                        className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                                                        onClick={(e) => { e.preventDefault(); handleRemoveImage(index); }}
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                        <label className="border border-black w-[150px] h-[200px] rounded-lg flex justify-center items-center text-[#a96a37] cursor-pointer" >
                                                            <span className="text-md text-[#a96a37]">Add More</span>
                                                            <input type="file"
                                                                accept="image/*"
                                                                multiple onChange={(e) => handleMultipleImagesUpload(e, setMultipleImages)}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                :
                                                <div className="flex items-center p-4 justify-center h-full w-full">

                                                    <label className="w-full h-full rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">

                                                        <div>
                                                            <span className="text-md text-gray-500">* Upload Images</span>
                                                            {/* <input type="file" onChange={(e) => handleMultipleImag(e)} className="hidden" /> */}
                                                            <input type="file"
                                                                accept="image/*"
                                                                multiple onChange={(e) => handleMultipleImagesUpload(e, setMultipleImages)}
                                                                className="hidden"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* product video */}
                        <div className="bg-white rounded-lg ">
                            <div className="">
                                <h4 className="text-md text-black font-semibold p-4">Product Video</h4>
                                <div className="border-[1px] border-t-[#ebac78] w-full  h-[300px]">
                                    {
                                        video ?
                                            <div className="relative flex items-center p-4 justify-center w-full">
                                                <div className="border border-black w-full h-[200px] rounded-lg overflow-hidden relative">
                                                    <video controls className="absolute top-0 left-0 w-full h-full object-cover ">
                                                        <source src={`${VIDEOURL}${video}`} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>

                                                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center  pointer-events-none">
                                                        <button
                                                            className="bg-[#a96a37] text-white p-1 rounded-lg pointer-events-auto"
                                                            onClick={() => setVideo(null)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            <div className="flex items-center p-4 justify-center h-full w-full">
                                                {
                                                    parseInt(progress) > 0 && progress < 100 ?

                                                        <div className="w-full flex justify-center items-center">
                                                            {/* <ProgressBar progress={progress} /> */}
                                                            <Spinner />
                                                        </div>
                                                        :
                                                        <label for="fileInput" className="w-full h-full rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                            <span className="text-md text-gray-500">* Upload Video</span>
                                                            <input type="file" id="fileInput" accept="video/*" className="hidden" onChange={(e) => handleVideoUpload(e, setVideo)} />
                                                        </label>
                                                }

                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* product pricing,meta */}
                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5 bg-white rounded-lg p-5 my-5 items-start">

                            <div className="">
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    Meta title
                                </label>
                                <input
                                    value={values.p_meta_title}
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="p_meta_title"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder=" Enter Meta title"
                                />
                            </div>
                            <div className="">
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    Meta Tags
                                </label>
                                <input
                                    value={values.p_meta_tags}
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="p_meta_tags"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder=" Enter Meta Tags"
                                />
                            </div>
                            <div className="lg:col-span-2 xl:col-span-3">
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    Meta description
                                </label>
                                <textarea
                                    value={values.p_meta_desc}
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="p_meta_desc"
                                    className="block  w-full h-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Short Description"
                                />

                            </div>
                            <div className="lg:col-span-2 xl:col-span-3 w-full">
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    Meta Image
                                </label>

                                <div className=" ">
                                    {
                                        metaImage ?
                                            <div className="flex items-center justify-center w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200">
                                                <div className="relative">
                                                    <div className="border border-black w-[150px] h-[200px] rounded-lg overflow-hidden relative">
                                                        <img src={`${IMAGEURL}${metaImage}`} className="absolute top-0 left-0 w-full h-full object-cover opacity-75" alt="name" />

                                                        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                            <button
                                                                className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                                onClick={() => SetMetaIMage(null)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="flex items-center justify-center w-full">
                                                {
                                                    parseInt(singleImageProgress) > 0 && singleImageProgress < 100 ?
                                                        <div className="w-full h-60 flex justify-center items-center">
                                                            <Spinner />
                                                        </div>
                                                        :
                                                        <label className="w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                            <span className="text-md text-gray-500">* Upload Image</span>
                                                            <input type="file" class="hidden" accept="image/*" onChange={(e) => handleMetaImageUpload(e, SetMetaIMage)} />

                                                        </label>
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Status
                                </label>
                                <div>
                                    <Select
                                        classNamePrefix="select"
                                        //defaultValue={userTypes[0]}
                                        placeholder="Select"
                                        value={statusOption}

                                        onChange={(selectedOption) => {
                                            setStatusOption(selectedOption)
                                            if (selectedOption) {
                                                setError("")
                                            }
                                        }}

                                        className="basic-single sm:text-sm "
                                        options={statusDataOption}
                                        styles={customStyles}
                                        isClearable
                                    />

                                </div>
                                {error?.statusError && <p className="text-red-500 text-sm ">{error?.statusError}</p>}
                            </div>
                            {
                                statusOption?.label === "Schedule" && (
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Schedule
                                        </label>
                                        <div className="flex">
                                            <input
                                                type="datetime-local"
                                                name="p_product_schedule_date"
                                                value={values.p_product_schedule_date}
                                                onChange={(event) => {
                                                    handleChange(event);
                                                }}
                                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>)
                            }
                        </div>
                    </div>
                    <div className="md:basis-1/4">
                        <div className="flex flex-col gap-5">
                            <div className="bg-white rounded-xl">
                                <div className="">
                                    <h4 className="text-md text-black font-semibold p-4">Product Categories</h4>
                                    <div className="border-[1px] border-t-[#ebac78] overflow-y-auto h-[300px]">
                                        <div className="p-5 flex flex-col gap-1 ">
                                            {
                                                categories.map((ele, index) => {
                                                    return (

                                                        <div className="" key={index}>
                                                            <div className="flex gap-3 items-center px-5">
                                                                <input type="checkbox"

                                                                    //checked={!!selectedCategories[ele.p_category_name]?.isChecked}

                                                                    checked={selectedCategories?.length > 0 && selectedCategories.find(cat => cat.catname === ele.p_category_name)?.ischecked}
                                                                    //checked={editProduct[0]?.p_category_id && editProduct[0]?.p_category_id.split(';').includes(ele?.p_category_name) || false}
                                                                    onChange={(e) =>
                                                                        handleCategoryChange(ele?.p_category_name, e.target.checked, ele?.category_id)
                                                                    }
                                                                    className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                <h5 className="text-sm text-gray-500">{ele?.p_category_name}</h5>
                                                            </div>
                                                            {
                                                                ele?.sub_category?.length > 0 && ele?.sub_category?.map((sb, ind) => {
                                                                    return (
                                                                        <div className="flex ml-8" key={ind}>
                                                                            <div className="flex gap-3 items-center px-5 ">
                                                                                <input type="checkbox"

                                                                                    // checked={
                                                                                    //     !!selectedCategories[ele.p_category_name]?.[
                                                                                    //         sb.cat_name
                                                                                    //     ]?.isChecked
                                                                                    // }
                                                                                    checked={
                                                                                        selectedCategories.find(cat => cat.catname === ele.p_category_name)?.subcat.find(sub => sub.subcatname === sb.cat_name)?.ischecked
                                                                                    }

                                                                                    //checked={editProduct[0]?.p_sub_category_id && editProduct[0]?.p_sub_category_id.split(';').includes(sb.cat_name) || false}

                                                                                    onChange={(e) =>
                                                                                        handleSubCategoryChange(
                                                                                            ele?.p_category_name,
                                                                                            sb?.cat_name,
                                                                                            e.target.checked,
                                                                                            sb?.id
                                                                                        )
                                                                                    }
                                                                                    className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                                <h5 className="text-sm text-gray-500">{sb?.cat_name}</h5>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white rounded-xl">
                                <div className="">
                                    <h4 className="text-md text-black font-semibold p-4">Product Brands</h4>
                                    <div className="border-[1px] border-t-[#ebac78] overflow-y-auto h-[250px]">
                                        <div className="p-5 flex flex-col gap-1 ">
                                            {
                                                brandData.map((ele, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className="flex gap-3 items-center px-5">
                                                                <input type="checkbox"
                                                                    // checked={!!selectedBrand[ele?.m_name]?.brandChecked}
                                                                    // onChange={(e) => handleBrandChange(ele?.m_name, ele?.m_designers_id, e.target.checked)}
                                                                    checked={selectedBrand?.brandname === ele?.m_name || selectedBrand?.brandID === ele?.m_designers_id}
                                                                    onChange={(e) => handleBrandChange(ele?.m_name, e.target.checked, ele?.m_designers_id)}
                                                                    className="rounded-sm  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                                <h5 className="text-sm text-gray-500">{ele?.m_name}</h5>
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white rounded-xl">
                                <div className="">
                                    <h4 className="text-md text-black font-semibold p-4">Product Image</h4>
                                    <div className="border-[1px] border-t-[#ebac78]  h-[300px]">
                                        <div className="flex items-center p-4 justify-center">
                                            {
                                                singleImage ?
                                                    <div className="relative">
                                                        <div className="border border-black w-[150px] h-[200px] rounded-lg overflow-hidden relative">
                                                            <img src={`${IMAGEURL}${singleImage}`} className="absolute top-0 left-0 w-full h-full object-cover opacity-75" alt="name" />

                                                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                                <button
                                                                    className="bg-[#a96a37] text-white p-1 rounded-lg"
                                                                    onClick={() => setSingleImage(null)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div className="flex items-center p-4 justify-center h-full w-full">
                                                        {
                                                            parseInt(singleImageProgress) > 0 && singleImageProgress < 100 ?
                                                                <div className="w-full flex justify-center items-center">
                                                                    <Spinner />
                                                                </div>
                                                                :

                                                                <label className="w-60 h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                                                    <span className="text-md text-gray-500">* Front Image</span>

                                                                    <input type="file" class="hidden" accept="image/*" onChange={(e) => handleSingleImageUpload(e, setSingleImage)} />

                                                                </label>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700"></hr>

                {/* submit.cancel button */}
                <div className="flex gap-5 justify-start ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button
                        
                        disabled={disableSku.checked}
                        onClick={() => {handleSubmit()}}
                        type="button"
                        className={`rounded-md border text-[#fff] shadow-sm px-4 py-2 text-sm font-medium ${
                            disableSku.checked===false ? 'bg-[#3a4b43] cursor-pointer' : 'bg-gray-400 cursor-none'
                          }`}
                        
                        //className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Submit
                    </button>
                </div>

            </form>


            {
                loading &&
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Spinner />
                    </div>
                </div>

            }

        </>
    )
}

export default AddProduct