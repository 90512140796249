import Api from "./Api";
import { toast } from "react-toast";

export default {
  // USER
  async getAllUsers() {
    try {
      const response = await Api().get("/api/admin/get-users");

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  // Dashboard
  async getAllDashboard(data) {
    try {
      var url = "/api/admin/get-order-reports";
      if (data.startDate && data.endDate) {
        url += "?startDate=" + data.startDate + "&endDate=" + data.endDate;
      }
      const response = await Api().get(url);
      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  // Dashboard Line Graph API
  async getAllDashboardLineGarph(data) {
    try {
      var url = "/api/admin/get-daywise-order-reports";
      if (data.startDate && data.endDate) {
        url += "?startDate=" + data.startDate + "&endDate=" + data.endDate;
      }
      const response = await Api().get(url);
      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  // PRODUCT
  async addProducts(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/products", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async editProducts(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/products", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async getProductImage(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/fileupload/uploadFile", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getAllProduct() {
    try {
      const response = await Api().get("/api/admin/products");

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getAllPageMeta() {
    try {
      const response = await Api().get("/api/admin/seo");

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getDesginerProductCount(id) {
    try {
      const response = await Api().get(
        `/api/admin/get-designer-product-count/${id}`
      );

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async getProductById(id) {
    try {
      const response = await Api().get(`/api/admin/products/?product_id=${id}`);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  // ORDERS
  async getAllOrder() {
    try {
      const response = await Api().get("/api/admin/get-orders");

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async addOrders(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/create-order", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async editOrders(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/orders", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getOrderById(id) {
    try {
      const response = await Api().get(`/api/admin/get-orders/?order_id=${id}`);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async getOrderStatus() {
    try {
      const response = await Api().get("/api/admin/get-order-status");

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  // CATEGORY

  async addCategory(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/product-category", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async editCategory(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/product-category", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getAllCategory() {
    try {
      const response = await Api().get("/api/admin/product-category");

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getSingleCategory(id) {
    try {
      const response = await Api().get(`/api/admin/product-category/${id}`);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getSingleSubCategory(id) {
    try {
      const response = await Api().get(`/api/admin/product-sub-category/${id}`);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  // ATTRIBUTE

  async getAllAttributes() {
    try {
      const response = await Api().get("/api/admin/product-attributes");
      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async addAttributesValue(payload) {
    try {
      const response = await Api().post("/api/admin/attribute-values", payload);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async getAllAttributesColorValue(id) {
    try {
      const response = await Api().get(
        `api/admin/attribute-values/?p_product_attribute_id=${id}`
      );
      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async addProductAttribute(data) {
    try {
      const response = await Api().post(`/api/admin/product-attributes`, data);
      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async addAttributeChild(data) {
    try {
      const response = await Api().post(`/api/admin/attribute-child`, data);
      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async getAttributes() {
    try {
      const response = await Api().get(`/api/admin/product-attributes`);
      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getAttributeChild() {
    try {
      const response = await Api().get(`/api/admin/attribute-child`);
      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async getChildAttriValue(id){
    try {
      const response = await Api().get(`api/admin/attribute-values/?p_child_attribute_id=${id}`);
      return response;
    } catch (error) {
      toast.error(error);
    }
  }
,

  // BRANDS
  async getAllBrands() {
    try {
      const response = await Api().get("/api/admin/designers");
      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async addBrand(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().post("/api/admin/designers", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async editBrand(body) {
    // const {dataImage} = payload
    try {
      const response = await Api().put("/api/admin/designers", body);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async getSingleBrand(id) {
    try {
      const response = await Api().get(`/api/admin/designers/${id}`);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },
  async getSingleSubBrand(id) {
    try {
      const response = await Api().get(`/api/admin/sub-designers/${id}`);

      return response;
    } catch (error) {
      toast.error(error);
    }
  },

  async checkSkuValue(value){
    try {
      const response = await Api().get(`/api/product/sku-check/${value}`);

      return response;
    } catch (error) {
      toast.error(error);
    }
  }
};
