import React, { useState, useEffect } from "react";
import {
  EyeIcon,
  ArrowUpIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import Breadcrumb from "../../../components/BreadCrumb";
import LineChart from "../../../components/LineChart";
import ColumnChart from "../../../components/ColumnChart";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import moment from "moment";

const HomePage = () => {
  const [data, setData] = useState([]);
  const [lineGraphData, setLineGraphData] = useState()
  const [loading, setLoading] = useState(true);

  const pages = [{ title: "Dashboard", href: "/", module_id: 1 }];
  const TotalValue = [
    {
      id: 1,
      price: "$3.456K",
      icon: EyeIcon,
      subtitle: "Total Views",
      percentage: "0.43%",
    },
    {
      id: 2,
      price: "$45.2K",
      icon: ShoppingCartIcon,
      subtitle: "Total Profit",
      percentage: "0.43%",
    },
    {
      id: 3,
      price: "$3.456K",
      icon: ShoppingBagIcon,
      subtitle: "Total Product",
      percentage: "0.43%",
    },
    {
      id: 4,
      price: "$3.456K",
      icon: UsersIcon,
      subtitle: "Total Users",
      percentage: "0.43%",
    },
  ];

  const [fetchDataTypes, setFetchDataTypes] = useState([
    "Year",
    "Last Month",
    "This Month",
    "Last 7 days",
    "Custom",
  ]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getProducts = async () => {
    try {
      const response = await MasterServices.getAllDashboard({
        startDate: startDate,
        endDate: endDate,
      });
      if (response?.data?.data?.length > 0) {
        setData(response?.data?.data[0]);
        setLoading(false);
      }
    } catch (error) {
      toast.error("error in getProducts");
    }
  };

  const getProductsLineGraph = async () => {
    try {
      let payload = { date: [], data: [] }
      const response = await MasterServices.getAllDashboardLineGarph({
        startDate: startDate,
        endDate: endDate,
      });
      if (response?.data?.data?.length > 0) {
        response?.data?.data[0]['total_order_price'].map(e => {
          payload.date.push(moment(e.date).format("DD MMM YYYY"));
        })
        response?.data?.data.map((e, i) => {
          let value = []
          let keys = Object.keys(e)[0]
          response.data.data[i][keys].map(e => value.push(e[keys]))
          payload.data.push({
            [keys]: value
          })
        })
        setLineGraphData(payload);
        setLoading(false);
      }
    } catch (error) {
      toast.error("error in getProductsLineGraph");
    }
  };

  const handleButtonClick = (type) => {
    setSelectedFilter(type);
    switch (type) {
      case "Year":
        setStartDate(moment().startOf("year").format("YYYY-MM-DD"));
        setEndDate(moment().endOf("year").format("YYYY-MM-DD"));
        break;
      case "Last Month":
        setStartDate(
          moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
        );
        setEndDate(
          moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
        );
        break;
      case "This Month":
        setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
        setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
        break;
      case "Last 7 days":
        setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "Custom":
        setStartDate("");
        setEndDate("");
        break;
      default:
        break;
    }
  };

  const fetchData = async () => {
    await getProducts();
    await getProductsLineGraph();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  return (
    <>
      <Breadcrumb pages={pages} />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-5 2xl:gap-8">
        {
          <>
            <div className="rounded-sm border border-stroke bg-white px-8 py-6 shadow-default dark:border-strokedark dark:bg-boxdark hover:scale-110 hover:duration-700 hover:ease-in-out ">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-200">
                <EyeIcon className="w-5 h-5 fill-primary dark:fill-white text-blue-600" />
              </div>
              <div className="mt-4 flex items-end justify-between">
                <div>
                  <h4 className="text-2xl font-semibold text-black">
                    {data.coupon_count}
                  </h4>
                  <span className="text-sm font-medium text-gray-400">
                    Coupon Count
                  </span>
                </div>
              </div>
            </div>
            <div className="rounded-sm border border-stroke bg-white px-8 py-6 shadow-default dark:border-strokedark dark:bg-boxdark hover:scale-110 hover:duration-700 hover:ease-in-out ">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-200">
                <EyeIcon className="w-5 h-5 fill-primary dark:fill-white text-blue-600" />
              </div>
              <div className="mt-4 flex items-end justify-between">
                <div>
                  <h4 className="text-2xl font-semibold text-black">
                    {data.placed_count}
                  </h4>
                  <span className="text-sm font-medium text-gray-400">
                    Placed Count
                  </span>
                </div>
              </div>
            </div>
            <div className="rounded-sm border border-stroke bg-white px-8 py-6 shadow-default dark:border-strokedark dark:bg-boxdark hover:scale-110 hover:duration-700 hover:ease-in-out ">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-200">
                <EyeIcon className="w-5 h-5 fill-primary dark:fill-white text-blue-600" />
              </div>
              <div className="mt-4 flex items-end justify-between">
                <div>
                  <h4 className="text-2xl font-semibold text-black">
                    {data.processing_count}
                  </h4>
                  <span className="text-sm font-medium text-gray-400">
                    Processing Count
                  </span>
                </div>
              </div>
            </div>
            <div className="rounded-sm border border-stroke bg-white px-8 py-6 shadow-default dark:border-strokedark dark:bg-boxdark hover:scale-110 hover:duration-700 hover:ease-in-out ">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-200">
                <EyeIcon className="w-5 h-5 fill-primary dark:fill-white text-blue-600" />
              </div>
              <div className="mt-4 flex items-end justify-between">
                <div>
                  <h4 className="text-2xl font-semibold text-black">
                    {data.purchase_count}
                  </h4>
                  <span className="text-sm font-medium text-gray-400">
                    Purchase Count
                  </span>
                </div>
              </div>
            </div>
            <div className="rounded-sm border border-stroke bg-white px-8 py-6 shadow-default dark:border-strokedark dark:bg-boxdark hover:scale-110 hover:duration-700 hover:ease-in-out ">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-200">
                <EyeIcon className="w-5 h-5 fill-primary dark:fill-white text-blue-600" />
              </div>
              <div className="mt-4 flex items-end justify-between">
                <div>
                  <h4 className="text-2xl font-semibold text-black">
                    {data.shipped_count}
                  </h4>
                  <span className="text-sm font-medium text-gray-400">
                    Shipped Count
                  </span>
                </div>
              </div>
            </div>
            <div className="rounded-sm border border-stroke bg-white px-8 py-6 shadow-default dark:border-strokedark dark:bg-boxdark hover:scale-110 hover:duration-700 hover:ease-in-out ">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-200">
                <EyeIcon className="w-5 h-5 fill-primary dark:fill-white text-blue-600" />
              </div>
              <div className="mt-4 flex items-end justify-between">
                <div>
                  <h4 className="text-2xl font-semibold text-black">
                    {data.total_order_price}
                  </h4>
                  <span className="text-sm font-medium text-gray-400">
                    Total Order Price
                  </span>
                </div>
              </div>
            </div>
            <div className="rounded-sm border border-stroke bg-white px-8 py-6 shadow-default dark:border-strokedark dark:bg-boxdark hover:scale-110 hover:duration-700 hover:ease-in-out ">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-200 dark:bg-slate-200">
                <EyeIcon className="w-5 h-5 fill-primary dark:fill-white text-blue-600" />
              </div>
              <div className="mt-4 flex items-end justify-between">
                <div>
                  <h4 className="text-2xl font-semibold text-black">
                    {data.total_shipping_price}
                  </h4>
                  <span className="text-sm font-medium text-gray-400">
                    Total Shipping Price
                  </span>
                </div>
              </div>
            </div>
          </>
        }
      </div>
      <div className="mt-5 flex flex-row justify-start gap-2">
        {fetchDataTypes.map((type, index) => (
          <button
            key={index}
            type="button"
            className={`text-center rounded-md border text-black bg-white shadow-sm px-4 py-2 text-sm ${
              selectedFilter === type ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleButtonClick(type)}
          >
            {type}
          </button>
        ))}
        {selectedFilter === "Custom" && (
          <>
            <div className="flex">
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={(e) => {
                  const startDate = e.target.value;
                  setStartDate(startDate);
                  // Update filters or perform any other action
                }}
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="flex">
              <input
                type="date"
                name="endDate"
                value={endDate}
                onChange={(e) => {
                  const endDate = e.target.value;
                  setEndDate(endDate);
                  // Update filters or perform any other action
                }}
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </>
        )}
      </div>
      <div className="my-5 flex flex-col xl:flex-row justify-center gap-5">
        <div className="basis-3/6 w-full bg-white p-4 h-full">
          {loading ? <p>Loading...</p> : <LineChart lineData={lineGraphData} />}
        </div>
        <div className="basis-3/6">
          <div className="basis-3/5 w-full bg-white p-4 h-full">
          {loading ? <p>Loading...</p> : <ColumnChart lineData={lineGraphData}/>}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
