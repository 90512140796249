import React, { useState } from "react";
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'rgba(209, 213, 219, 0.9)', // Initial border color
        boxShadow: state.isFocused ? '0 0 0 0px #ebac78' : 'none', // Box shadow on focus
        '&:hover': {
            borderColor: '#ebac78', // Hover border color
        },

    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'gray-200', // Change dropdown indicator color if needed
    }),

    clearIndicator: (provided) => ({
        ...provided,
        color: 'black', // Change clear indicator color if needed
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black', // Change selected value text color if needed

    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ebac78' : state.isFocused ? '#f3e5d7' : 'white', // Background color on select and focus
        color: state.isSelected ? 'white' : 'black', // Text color on select and focus
        '&:hover': {
            backgroundColor: state.isSelected ? '#ebac78' : '#f3e5d7', // Hover background color
        },
    }),
};

const AddParentAttribute = () => {

    const options = [
        { value: 'Y', label: 'Yes' },
        { value: 'N', label: 'No' }
    ];

    const options1 = [
        { value: 'Currency', label: 'Currency' }

    ];


    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState([]);
    const navigate = useNavigate()

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            event.preventDefault();
            const value = inputValue.trim();
            if (!items.includes(value)) {
                setItems([...items, value]);
            }
            setInputValue('');
        }
    };

    const removeItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };

    const [data, setdata] = useState({
        p_product_attribute_name: null,
        p_product_attribute_value: null,
        is_storable: "",
        // p_product_attribute_type_id: null,
        slug: null,
        m_meta_title: null,
        m_meta_desc: null,
        m_meta_tags: null
    });


    const handleChange = (e) => {
        e.preventDefault();
        setdata((data) => ({ ...data, [e.target.name]: e.target.value }))
        // console.log(e.target.value)
        // console.log(e.target.name)
    }

    const handleDropdown = (val) => {

        console.log(val)

        setdata(data => ({ ...data, is_storable: val.value }))

    }

    const handleDropdown1 = (val) => {

        // console.log(val)

        setdata(data => ({ ...data, p_product_attribute_value: val.value }))

    }


    const submitform = async () => {
        let finaldata = data;
        finaldata = { ...finaldata, items }
        let resp = await MasterServices.addProductAttribute(finaldata)
        if (resp.data.success) {
            console.log(resp.data)
            setdata({
                "p_product_attribute_name": null,
                "p_product_attribute_value": null,
                "is_storable": "",
                "slug": null,
                "m_meta_title": null,
                "m_meta_desc": null,
                "m_meta_tags": null
            });
            setItems([]);
            setInputValue("")
        }
        else {
            console.log(resp.data)
        }
    }

    const clearForm  =()=>{
        navigate("/parentattribute")
    }



    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add Parent Attribute</h1>
            </div>
            <div className="py-5 flex flex-col gap-5 ">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="">
                            <input
                                value={data.p_product_attribute_name}
                                // onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                name="p_product_attribute_name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Name"
                            />

                        </div>
                    </div>

                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Type
                        </label>
                        <div className="">
                            {/* <input
                                value={data.p_product_attribute_value}
                                // onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                name="p_product_attribute_value"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Value"
                            /> */}

                            <Select
                                onChange={handleDropdown1}
                                classNamePrefix="select"
                                placeholder="Select"
                                name="p_product_attribute_value"
                                className="basic-single sm:text-sm"
                                options={options1}
                                styles={customStyles}
                            />

                        </div>
                    </div>

                    {/* <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Type
                        </label>
                        <div>
                            <Select
                                classNamePrefix="select"
                                //defaultValue={userTypes[0]}
                                placeholder="Select"
                                //value={selectedUserType ? selectedUserType : userTypes[0]}
                                // // onBlur={handleBlur}
                                name="userStatus"
                                className="basic-single sm:text-sm"

                            />

                        </div>
                    </div> */}
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Is storable
                        </label>
                        <div>
                            <Select
                                onChange={handleDropdown}
                                classNamePrefix="select"
                                placeholder="Select"
                                name="is_storable"
                                className="basic-single sm:text-sm"
                                options={options}
                                styles={customStyles}
                            />

                        </div>
                    </div>


                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm">
                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Title
                        </label>
                        <div className="">
                            <input
                                value={data.m_meta_title}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_title"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Title"
                            />

                        </div>
                    </div>

                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta Tags
                        </label>
                        <div className="">
                            <input
                                value={data.m_meta_tags}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_tags"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Tags"
                            />

                        </div>
                    </div>

                    <div>
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Values
                        </label>
                        <div className="">

                            <div className="relative flex flex-wrap items-center">
                                {items.map((item, index) => (
                                    <div key={index} className="flex items-center sm:text-sm bg-gray-200 rounded px-2 py-1 m-1">
                                        <span>{item}</span>
                                        <button className="ml-1 sm:text-sm text-red-500 hover:text-red-700" onClick={() => removeItem(index)}>&times;</button>
                                    </div>
                                ))}
                                <input
                                    type="text"
                                    className="flex-grow rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    placeholder="Type and Press Enter"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>

                        </div>


                    </div>



                    <div className="sm:col-span-3">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta description
                        </label>
                        <div className="">
                            <textarea
                                value={data.m_meta_desc}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="m_meta_desc"
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Description"
                            />

                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  gap-5 bg-white rounded-lg p-5 shadow-sm">
                    <div className="col-start-0 col-end-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Slug
                        </label>
                        <div className="">
                            <input
                                value={data.slug}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name="slug"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                placeholder="Slug"
                            />

                        </div>
                    </div>
                    {/* <div className="col-start-0 col-end-2">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Meta description
                        </label>
                        <div className="">
                            <textarea
                                value=""
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                type="text"
                                name=""
                                className="block w-full rounded-md border-0 py-1.5 h-60 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Description"
                            />

                        </div>
                    </div> */}
                    <div className="col-span-1">
                        <label className="flex  text-sm font-medium leading-6 text-gray-900">
                            Thumbnail
                        </label>
                        <div className="flex">
                            <div className="flex items-center  justify-center h-full w-full">
                                <label className="w-full h-60 rounded-lg border-2 border-dashed border-gray-400 bg-gray-200 flex items-center justify-center text-white cursor-pointer">
                                    <span className="text-md text-gray-500">* Front Image</span>

                                    <input type="file" class="hidden"
                                    //onChange={(e) => handleSingleImageUpload(e, setSingleImage)}
                                    />

                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="flex gap-5 justify-end ">
                    <button
                        onClick={clearForm}
                        type="button"
                        className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={submitform}
                        type="submit"
                        className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                    >
                        Submit
                    </button>
                </div>
            </div >
        </>
    )
}

export default AddParentAttribute