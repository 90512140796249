import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import DashboardLayout from "../layout/dashboardLayout";
import HomePage from "../container/pages/home/HomePage";
import Login from "../container/pages/login/Login";
import Orders from "../container/pages/orders/Orders";
import AddOrder from "../container/pages/orders/AddOrder";
import Product from "../container/pages/product/Product";
import AddProduct from "../container/pages/product/AddProduct"
import Category from "../container/pages/category/Category";
import AddCategory from "../container/pages/category/AddCategory";
import Brand from "../container/pages/brands/Brand";
import AddBrand from "../container/pages/brands/AddBrand";
import AddTag from "../container/pages/tags/AddTag"
import TagPage from "../container/pages/tags/TagPage"
import SEOPage from "../container/pages/seo/SEOPage"
import AddSEOPage from "../container/pages/seo/AddSEO"
import ParentAttribute from "../container/pages/parentAttribute/ParentAttribute";
import AddParentAttribute from "../container/pages/parentAttribute/AddParentAttribute";
import ChildAttribute from "../container/pages/childAttribute/ChildAttribute";
import AddChildAttribute from "../container/pages/childAttribute/AddChildAttribute";
const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/order-page" element={<Orders />} />
          <Route path="/product-page" element={<Product />} />
          <Route path="/category-page" element={<Category />} />
          <Route path="/brand-page" element={<Brand />} />
          <Route path="/tag-page" element={<TagPage />} />
          <Route path="/seo" element={<SEOPage />} />
          <Route path="/add-seo" element={<AddSEOPage />} />
          <Route path="/parentattribute" element={<ParentAttribute />} />
          <Route path="/childAttribute" element={<ChildAttribute />} />

          <Route path="/add-order" element={<AddOrder />} />
          <Route path="/editorder/:order_id" element={<AddOrder />} />
          <Route path="/add-product" element={<AddProduct/>} />
          <Route path="/editproduct/:product_id" element={<AddProduct />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/editcategory/:category_id" element={<AddCategory />} />
          <Route path="/editSubCategory/:category_id" element={<AddCategory />} />
          <Route path="/add-brand" element={<AddBrand />} />
          <Route path="/editbrand/:brand_id" element={<AddBrand />} />
          <Route path="/editSubBrand/:brand_id" element={<AddBrand />} />
          <Route path="/add-tag" element={<AddTag />} />
          <Route path="/add-parentAttribute" element={<AddParentAttribute />} />
         <Route path="/add-childAttribute" element={<AddChildAttribute />} />
        </Route>
        {/* <Route path="/" element={<DashboardLayout />} /> */}
      </Routes>
    </Router>
  );
};
export default RoutesConfig;
