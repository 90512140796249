import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";



export const order_columns = () => [
    {
        Header: "Transaction id",
        accessor: "m_transction_id",
    },
  
    {
        Header: "Email",
        accessor: "m_billing_email",
    },
    {
        Header: "Amount",
        accessor: "m_total_order_price",
    },
   
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const order_id = cell.row.original.m_order_id;
            console.log("order_id", cell.row.original)

            
            return (
                <>
                    <div className="flex gap-5">
                        <Link className=""
                            to={`/editorder/${order_id}`}
                        >
                            <span class=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#557a67] ">Edit</span>
                        </Link>

                    </div>
                </>
            )
        }
    },

]

export const summary_columns = () => [
    {
        Header: "productname",
         accessor: (c) => { 
            console.log("summary_columns",c)
            return <span>{c.p_product_name}</span> 
        }
    },
    {
        Header: "p_product_sku",
        accessor: "p_product_sku",
    },
    {
        Header: "p_product_price",
        accessor: "p_product_price",
    },
    {
        Header: "p_stok_quantity",
        accessor: "p_stok_quantity",
    },
    {
        Header: "total",
        accessor: "total",
        Cell:(cell)=>{
            const productPrice = cell?.row?.original?.p_product_price;
            const productQuantity = cell?.row?.original?.p_stok_quantity;
            return(
                <>
                    <span className="text-sm text-gray-500">{productPrice*productQuantity}</span>
                </>
            )
        }
    },
]

export const product_columns = () => [
    {
        Header: "Name",
        accessor: "p_product_name",
    },
    {
        Header: "short description",
        accessor: "p_product_short_desc",
    },
    {
        Header: "sku",
        accessor: "p_product_sku",
    },
    {
        Header: "price",
        accessor: "p_product_price",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const product_id = cell.row.original.p_porduct_id;
            //console.log("product_id", product_id)
            console.log("product_id",cell.row.original)
            return (
                <>
                    <div className="flex gap-5">
                        <Link className=""
                            to={`/editproduct/${product_id}`}
                        >
                            <span className=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#557a67] ">Edit</span>
                        </Link>
                        <Link className=""
                        // to={`/editproduct/${product_id}`}
                        >
                            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">View</span>
                        </Link>
                    </div>
                </>
            )
        }
    },
]

export const category_columns = ({subCatDataHandler}) => [
    {
        Header: "category_id",
        accessor: "category_id",
    },
    {
        Header: "p_category_name",
        accessor: "p_category_name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const category_id = cell.row.original.category_id
            console.log("category_id", category_id);
            const subCatData = cell?.row?.original?.sub_category;

            console.log("subCateg", cell.row.original)
            return (
                <>
                    <div className="flex gap-5">
                        <button className=""
                            onClick={()=>{
                                subCatDataHandler(subCatData);
                                // setIsOpen((prev)=>!prev)
                            }}
                        >
                            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">view</span>
                        </button>
                        <Link className=""
                            to={`/editcategory/${category_id}`}
                        >
                            <span className=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#557a67] ">Edit</span>
                        </Link>

                    </div>
                </>
            )
        }
    },
]

export const subCategory_columns = ()=>[
    {
        Header: "id",
        accessor: "id",
    },
    {
        Header: "cat_name",
        accessor: "cat_name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const subCat_id = cell.row.original.id;
            // console.log("brand_id", brand_id)
            return (
                <>
                    <div className="flex gap-5">
                       
                        <Link
                            to={`/editSubCategory/${subCat_id}`}
                            className=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#557a67] ">
                            Edit
                        </Link>
                    </div>
                </>
            )
        }
    },
]

export const brand_columns = ({subBrandHandler}) => [
    {
        Header: "Id",
        accessor: "m_designers_id",
    },
    {
        Header: "name",
        accessor: "m_name",
        // Cell:(cell)=>{
        //     console.log("brand_columns",cell)
        // }
    },

    
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const brand_id = cell.row.original.m_designers_id;
            const subBrandData = cell?.row?.original.subdesigners
            console.log("subBrandData", subBrandData)

            return (
                <>
                    <div className="flex gap-5">
                        <button className=""
                            onClick={()=>{
                                subBrandHandler(subBrandData);
                                // setIsOpen((prev)=>!prev)
                            }}
                        >
                            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">view</span>
                        </button>
                        <Link className=""
                            to={`/editBrand/${brand_id}`}
                        >
                            <span className=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#557a67] ">Edit</span>
                        </Link>

                    </div>
                </>
            )
        }
    },
]
export const subBrand_columns = ()=>[
    {
        Header: "m_designers_id",
        accessor: "m_designers_id",
    },
    {
        Header: "m_name",
        accessor: "m_name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            const subBrand_id = cell.row.original.m_sub_designers_id;
            console.log("subBrand_id", subBrand_id)
            return (
                <>
                    <div className="flex gap-5">
                       
                        <Link
                            to={`/editSubBrand/${subBrand_id}`}
                            className=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#557a67] ">
                            Edit
                        </Link>
                    </div>
                </>
            )
        }
    },
]

export const tag_columns = () => [
    {
        Header: "name",
        accessor: "name",
    },
    {
        Header: "description",
        accessor: "description",
    },
    {
        Header: "slug",
        accessor: "slug",
    },
    {
        Header: "count",
        accessor: "count",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex gap-5">
                        <Link className="text-sm text-blue-500">
                            View
                        </Link>
                        <Link className="text-sm text-[#3a4b43]">
                            Edit
                        </Link>
                    </div>
                </>
            )
        }
    },
]

export const page_meta_columns = () => [
    {
        Header: "Page",
        accessor: "name",
    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex gap-5">
                        <Link className="text-sm text-blue-500">
                            View
                        </Link>
                        <Link className="text-sm text-[#3a4b43]">
                            Edit
                        </Link>
                    </div>
                </>
            )
        }
    },
]


export const parent_attribute_columns = () => [
    {
        Header: "name",
        accessor: "p_product_attribute_name",
        Cell: (cell) => {
            console.log("parent_attribute_columns", cell.row.original)
            return (
                <span>{cell.row.original.p_product_attribute_name}</span>
            )

        }

    },
    {
        Header: "status",
        accessor: "p_product_attribute_active",
        Cell: (cell) => {
            console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.p_product_attribute_active
            return (
                <span className={`${activeStatus === "Y" ? "text-green-500" : "text-red-400"} text-sm`}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex gap-5">
                        <Link className=""
                        // to={`/editproduct/${product_id}`}
                        >
                            <span class=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#557a67] ">Edit</span>
                        </Link>
                        <Link className=""
                        // to={`/editproduct/${product_id}`}
                        >
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">View</span>
                        </Link>
                    </div>
                </>
            )
        }

    },


]

export const child_attribute_columns = () => [
    {
        Header:'Parent Name',
        accessor:"p_product_attribute_name"
    },
    {
        Header: "name",
        accessor: "p_child_attribute_name",
        Cell: (cell) => {
            console.log("child_attribute_columns", cell.row.original)
            return (
                <span className="text-sm">{cell.row.original.p_child_attribute_name}</span>
            )
        }

    },
    {
        Header: "status",
        accessor: "p_child_attribute_active",
        Cell: (cell) => {
            console.log("parent_attribute_columns", cell.row.original)
            const activeStatus = cell.row.original.p_child_attribute_active
            return (
                <span className={`${activeStatus === "Y" ? "text-green-500" : "text-red-400"} text-sm`}>{activeStatus === "Y" ? "Active" : "Inactive"}</span>
            )

        }

    },
    {
        Header: "action",
        accessor: "action",
        Cell: (cell) => {
            return (
                <>
                    <div className="flex gap-5">
                        <Link className=""
                        // to={`/editproduct/${product_id}`}
                        >
                            <span class=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-[#557a67] ">Edit</span>
                        </Link>
                        <Link className=""
                        // to={`/editproduct/${product_id}`}
                        >
                            <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">View</span>
                        </Link>
                    </div>
                </>
            )
        }

    },
]