import React, { useEffect, useState } from "react";
import Select from "react-select"
import { PlusCircleIcon, ArrowDownCircleIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import SummaryTable from "../../../components/tables/summarytable";
import { summary_columns } from "../../../components/tables/tableheader";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
    CountrySelect,
    StateSelect,
    GetCountries,
    GetState
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import MasterServices from "../../../ApiServices/MasterServices";
import { ToastContainer, toast } from "react-toast";
import Spinner from "../../../components/Spinner";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: 'rgba(209, 213, 219, 0.9)', // Initial border color
        boxShadow: state.isFocused ? '0 0 0 0px #ebac78' : 'none', // Box shadow on focus
        '&:hover': {
            borderColor: '#ebac78', // Hover border color
        },

    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'gray-200', // Change dropdown indicator color if needed
    }),

    clearIndicator: (provided) => ({
        ...provided,
        color: 'black', // Change clear indicator color if needed
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black', // Change selected value text color if needed

    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ebac78' : state.isFocused ? '#f3e5d7' : 'white', // Background color on select and focus
        color: state.isSelected ? 'white' : 'black', // Text color on select and focus
        '&:hover': {
            backgroundColor: state.isSelected ? '#ebac78' : '#f3e5d7', // Hover background color
        },
    }),
};

const AddOrder = () => {
    const [shippingFlag, setShippingFlag] = useState(true)
    const [billingFlag, setBillingFlag] = useState(true)
    const [loading, setLoading] = useState(true)
    const [shipmentTrackFlag, setShipmentTrackFlag] = useState(true)
    const [country, setCountry] = useState({});
    const [state, setState] = useState({});

    const [shipCountry, setShipCountry] = useState({})
    const [shipState, setShipState] = useState({})
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [compnayName, setCompanyName] = useState("")
    const [addressOne, setAddressOne] = useState("")
    const [addressTwo, setAddressTwo] = useState("")
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState();
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState();
    const [transactionId, setTransactionId] = useState();
    const [customerData, setCustomerData] = useState([])
    const [customerOption, setCustomerOption] = useState([])
    const [selectCustomer, setSelectCustomer] = useState({})
    const [selectPaymentMethod, setSelectPaymentMethod] = useState({})
    const [selectShipPayMethod, setSelectShipPayMethod] = useState({})
    const [orderStatus, setOrderStatus] = useState({})
    const [orderStatusOption, setOrderStatusOption] = useState([])
    const [productData, setProductData] = useState([])
    const [productOption, setProductOption] = useState([])
    const [selectProduct, setSelectProduct] = useState({})
    const [selectMarkOrder, setSelectMarkOrder] = useState({})
    const [selectShipProvider, setSelectShipProvider] = useState({})
    const [selectOrderAction, setSelectOrderAction] = useState({})
    const [countries, setCountries] = useState([]);
    const [billstateOption, setBillStateOption] = useState([]);
    const [shipStateOption, setShipStateOption] = useState([])
    const [editOrder, setEditOrder] = useState([])
    const navigate = useNavigate();
    const [summaryDataList, setSummaryDataList] = useState([])
    const { order_id } = useParams();
    const [addressId,setAddressId] = useState({
        m_billing_address_id:null,
        m_shipping_address_id:null
    })

    const formData = {

        b_firstName: "",
        b_lastName: "",
        b_companyName: "",
        b_addressOne: "",
        b_addressTwo: "",
        b_city: "",
        b_zipCode: "",
        b_email: "",
        b_phone: "",
        b_transactionId: "",
        b_billing_date: "",
        coupon: "",
        tracking_number: "",
        tracking_ship_date: ""

    }
    const [initialValues, setInitialValues] = useState(formData);

    useEffect(() => {
        const fetchData = async () => {
            await getAllOrderStatus()
        }
        fetchData()
    }, [])

    const summaryData = [
        {
            productname: "xyzdf",
            sku: 122,
            cost: 2000,
            quantity: 2,
            total: 400000
        }
    ]

    const getAllOrderStatus = async () => {
        try {

            const response = await MasterServices.getOrderStatus();
            console.log("response", response)
            if (response?.data?.data?.length > 0) {
                const filter = response?.data?.data.map(ele => ({
                    label: ele?.m_order_status_value,
                    value: ele?.m_order_status_id

                }))
                setOrderStatusOption(filter)
            }

        } catch (error) {
            toast.error(error)
        }
    }



    const paymentMethod = [
        {
            value: 0,
            label: "debit",

        },
        {
            value: 1,
            label: "credit",

        },
    ]

    const markOrder = [
        {
            value: 1,
            label: "Shipped",
            key: "shippped"
        },
        {
            value: 2,
            label: "Partially shipped",
            key: "partially"
        }
    ]

    const shippingProvider = [
        {
            value: 1,
            label: "abc"
        },
        {
            value: 2,
            label: "xyz"
        }
    ]

    const orderAction = [
        {
            value: 1,
            label: "abc"
        },
        {
            value: 2,
            label: "xyz"
        }
    ]

    useEffect(() => {
        const fetchData = async () => {
            await getUsers();
            await getProducts()
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (customerData?.length > 0 && productData?.length > 0) {
            setLoading(false);
        }
    }, [customerData, productData]);

    const getSingleOrder = async (id) => {
        try {

            const response = await MasterServices.getOrderById(id);
            console.log("getSingleOrder", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setEditOrder(response?.data?.data)
            }
        }
        catch (error) {
            toast.error(error)
        }

    }

    const getUsers = async () => {
        const response = await MasterServices.getAllUsers();
        console.log("response", response)
        if (response?.data?.data?.length > 0) {
            setCustomerData(response?.data?.data)
            const optiondata = response?.data?.data.map((ele) => ({
                value: ele?.u_user_id,
                label: ele?.u_user_first_name + " " + ele?.u_user_last_name
            }))
            setCustomerOption(optiondata)
        } else {
            setCustomerData([])
        }
    }

    const getProducts = async () => {
        try {

            const response = await MasterServices.getAllProduct();
            console.log("getProducts", response?.data?.data)
            if (response?.data?.data?.length > 0) {
                setProductData(response?.data?.data)

                let dataoption = response?.data?.data?.map((ele) => ({
                    ...ele,
                    value: ele?.p_porduct_id,
                    label: ele?.p_product_sku,

                }))
                if(dataoption?.length>0){
                    setProductOption(dataoption)
                }
                

            }
        } catch (error) {
            toast.error("error in getProducts")
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (order_id) {
                await getSingleOrder(order_id)
            }
        }
        fetchData()
    }, [order_id])

    const DateFormatter = (bdate) => {
        // const dateStr = "2024-06-13T00:00:00.000Z";
        const date = new Date(bdate);

        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getUTCFullYear();

        const formattedDate = `${year}-${month}-${day}`;
        console.log("formattedDate", formattedDate)
        return formattedDate
    }



    useEffect(() => {
        if (order_id && editOrder?.length > 0) {
            editOrder.forEach((item) => {

                if (item?.address?.length > 0) {
                    item?.address.forEach((ele) => {
                        if (ele?.u_address_type === "billing") {
                            setInitialValues({
                                ...values,
                                b_firstName: ele?.u_user_name?.split(' ')[0],
                                b_lastName: ele?.u_user_name?.split(' ')[1],
                                b_companyName: ele?.u_company_name,
                                b_addressOne: ele?.u_address_value_1,
                                b_addressTwo: ele?.u_address_value_2,
                                b_city: ele?.u_city,
                                b_zipCode: ele?.u_pin_number,
                                b_email: ele?.u_email_address,
                                b_phone: ele?.u_mobile_number,
                               
                            })
                            if(ele?.u_country){
                                setCountry({
                                    label:ele?.u_country.split("-")[0],
                                    value:ele?.u_country.split("-")[1],
                                    
                                  })
        
                                  GetState(parseInt(ele?.u_country.split("-")[1])).then((result) => {
                                    const filter = result.map((ele) => ({
                                        value: ele?.id,
                                        label: ele?.name
                                    }))
                                   
                                    setBillStateOption(filter);
                                });
                            }
                         
                            if(ele?.u_address_id){
                                setAddressId((prev)=>({
                                    ...prev,
                                    m_billing_address_id: ele?.u_address_id
                                }))
                            }
                            setState(prev=>({
                                ...prev,
                                label: ele?.u_state
                            }))
                        }
                        if (ele?.u_address_type === "shipping") {
                            setFirstName(ele?.u_user_name?.split(' ')[0]);
                            setLastName(ele?.u_user_name?.split(' ')[1]);
                            setCompanyName(ele?.u_company_name);
                            setAddressOne(ele?.u_address_value_1);
                            setAddressTwo(ele?.u_address_value_2);
                            setCity(ele?.u_city);
                            setZipCode(ele?.u_pin_number);
                            setEmail(ele?.u_email_address);
                            setPhone(ele?.u_mobile_number)
                            setShipCountry(prev=>({
                                ...prev,
                                label:ele?.u_country
                            }))

                            if(ele?.u_country){
                                setShipCountry({
                                    label:ele?.u_country?.split("-")[0],
                                    value:ele?.u_country?.split("-")[1],
                                })
                                GetState(parseInt(ele?.u_country?.split("-")[1])).then((result) => {
                                    const filter = result.map((ele) => ({
                                        value: ele?.id,
                                        label: ele?.name
                                    }))
                                   
                                    setShipStateOption(filter);
                                });
                                setShipState(prev=>({
                                    ...prev,
                                    label: ele?.u_state
                                }))

                            }
                            if(ele?.u_address_id){
                                setAddressId((prev)=>({
                                    ...prev,
                                    m_shipping_address_id:ele?.u_address_id
                                }))
                            }
                        }
                        else{
                           

                            setInitialValues((prev)=>({
                                ...prev,
                                b_transactionId:item?.m_transction_id,
                                coupon: item?.m_coupon_code,
                                b_billing_date:DateFormatter(item?.m_billing_date),
                                tracking_number:item?.m_order_tracking_number,
                                tracking_ship_date:DateFormatter(item?.m_tarcking_ship_date),
                            }))
                        }
                    })
                }
                setOrderStatus(prev => ({
                    ...prev,
                    label: item?.m_order_status

                }))

                if (item?.m_shipped === "Y" || item?.m_partially_shipped === "Y") {
                    setSelectMarkOrder({
                        partially: item?.m_partially_shipped === "Y" ? true : false,
                        shippped: item?.m_shipped === "Y" ? true : false
                    })
                }

                if (item?.u_user_id) {
                    customerOption?.length > 0 && customerOption?.forEach((ele) => {
                        if (parseInt(ele?.value) === parseInt(item?.u_user_id)) {
                           
                            setSelectCustomer({
                                value: item?.u_user_id,
                                label: ele?.label
                            })
                        }
                    })
                }

                if(item?.m_shipping_provider_id || item?.m_shipping_provider_name ){
                    setSelectShipProvider({
                        value:item?.m_shipping_provider_id,
                        label:item?.m_shipping_provider_name
                    })
                }

                if(item?.p_product_id){
                    console.log("productOption",productOption)
                    productOption?.length>0&& productOption?.forEach((ele)=>{
                        if(parseInt(ele?.value)===parseInt(item?.p_product_id)){
                            console.log("unique")
                            setSelectProduct({
                                ...ele,
                                label:ele?.label,
                                value:item?.p_product_id
                            })
                            setSummaryDataList([{
                                ...ele,
                                label:ele?.label,
                                value:item?.p_product_id
                            }])
                        }
                    })
                }

            })
        }
    }, [editOrder,productOption,customerOption])


    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        getFieldProps,
    } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values) => {

            const body = {
                ...values,
                s_firstName: firstName,
                s_lastName: lastName,
                s_compnayName: compnayName,
                s_addressOne: addressOne,
                s_addressTwo: addressTwo,
                s_city: city,
                s_zipCode: zipCode,
                s_email: email,
                s_phone: phone,
                s_transactionId: transactionId,
                s_shipCountry: shipCountry?.label ? `${shipCountry?.label}-${shipCountry?.value}` : "",
                s_state: shipState ? shipState?.label : "",

                customername: selectCustomer?.value ? selectCustomer?.value : null,
                orderStatus: orderStatus ? orderStatus.label : "",
                product: selectProduct?.label ? selectProduct?.label : "",
                p_product_id: selectProduct?.value ? selectProduct?.value : "",
                m_shipping_provider_id: selectShipProvider?.value ? selectShipProvider?.value : "",
                m_shipping_provider_name: selectShipProvider?.label ? selectShipProvider?.label : "",

                Shipped: selectMarkOrder?.shipped ? true : false,
                Partially_shipped: selectMarkOrder?.partially ? true : false,

                orderAction: selectOrderAction ? selectOrderAction.label : "",
                b_country: country ? `${country?.label}-${country?.value}` : "",
                b_state: state ? state?.label : "",

                payment_method: selectPaymentMethod?.label ? selectPaymentMethod?.label : null,
                m_billing_address_id: addressId?.m_billing_address_id ? addressId?.m_billing_address_id:null,
                m_shipping_address_id  : addressId?.m_shipping_address_id ?  addressId?.m_shipping_address_id : null
                
            }

            console.log("body", body)

            if (editOrder?.length > 0 && order_id) {
                editOrderSubmit(body)
            } else {
                addOrderSubmit(body)
            }

        }
    })


    const editOrderSubmit = async (body) => {
        try {
            const response = await MasterServices.editOrders(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                toast.success("Order Edit Successfully");
                navigate("/order-page")
                clearForm(); // Optionally clear the form after successful submission
            } else {
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            toast.error("An error occurred while editing the order")
        }
    }

    const addOrderSubmit = async (body) => {
        try {
            const response = await MasterServices.addOrders(body);
            console.log("body", response)
            if (response?.data?.success === true) {
                toast.success("Order Added Successfully");
                navigate("/order-page")
                clearForm(); // Optionally clear the form after successful submission
            } else {
                toast.error(response?.data?.message);
            }
        }
        catch (error) {
            toast.error("An error occurred while adding the order")
        }
    }

    useEffect(() => {
        GetCountries().then((result) => {
            const filter = result?.map((ele) => ({
                label: ele?.name,
                value: ele?.id
            }))
            setCountries(filter);
            console.log("result", filter)
        });
    }, [])

    const copyBillingHandle = () => {


        setFirstName(values.b_firstName)
        setLastName(values.b_lastName);
        setCompanyName(values.b_companyName);
        setAddressOne(values.b_addressOne);
        setAddressTwo(values.b_addressTwo);
        setCity(values.b_city);
        setZipCode(values.b_zipCode);
        setEmail(values.b_email);
        setPhone(values.b_phone);
        setTransactionId(values.b_transactionId)

        setShipCountry(country)
        setShipState(state)
        setSelectShipPayMethod(selectPaymentMethod)
    }

    const clearForm = () => {
        navigate("/order-page")
        // setInitialValues(initialValues)
        // setFirstName("");
        // setLastName("")
        // setCompanyName("")
        // setAddressOne("")
        // setAddressTwo("")
        // setCity("")
        // setZipCode("")
        // setEmail("")
        // setPhone()
        // setTransactionId()
        // setSelectCustomer({})
        // setSelectPaymentMethod({})
        // setSelectShipPayMethod({})
        // setSelectProduct({})
        // setSelectMarkOrder({})
        // setSelectShipProvider({})
        // setSelectOrderAction({})
    }

    const handleMarkOrder = (name, isChecked) => {

        const updatedState = markOrder.reduce((acc, ele) => {
            acc[ele.key] = ele.key === name;
            return acc;
        }, {});

        console.log("updatedState", updatedState)
        setSelectMarkOrder(updatedState);

    }


    console.log("selectProduct", selectProduct)
    return (
        <>
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add Order</h1>
            </div>
            <div className="py-5">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                }}>
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 bg-white rounded-lg p-5 shadow-sm" >
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Customer
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select"

                                    value={selectCustomer}
                                    onChange={(selectOption) => {
                                        console.log("setSelectCustomer", selectOption)
                                        setSelectCustomer(selectOption)
                                    }
                                    }
                                    className="basic-single sm:text-sm"
                                    options={customerOption}
                                    styles={customStyles}

                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Status
                            </label>
                            <div>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="select"
                                    value={orderStatus}
                                    onChange={(selectOption) => setOrderStatus(selectOption)}
                                    name="orderStatus"
                                    className=" sm:text-sm"
                                    options={orderStatusOption}
                                    styles={customStyles}
                                />

                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Date Picker
                            </label>
                            <div className="flex">
                                <input
                                    type="date"
                                    name="b_billing_date"
                                    value={values.b_billing_date}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}

                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                />
                            </div>
                        </div>
                    </div>

                    <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700"></hr>
                    {/* {billing address} */}
                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm">

                        <div className="flex justify-between items-center">
                            <label className="text-black text-lg font-medium">Address</label>
                            <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setBillingFlag((prev) => !prev)} /></div>
                        </div>
                        <div className={`${billingFlag ? "flex flex-col gap-5 mt-5" : "hidden "}`}>
                            <h4 className="text-sm text-[#0094FF]" >Load Billing Address</h4>
                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 items-center">
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        First Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_firstName}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            // onBlur={handleBlur}
                                            type="text"
                                            name="b_firstName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="First Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Last Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_lastName}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_lastName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Last Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Company Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_companyName}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_companyName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Company Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Address line 1
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_addressOne}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_addressOne"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Address 1"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Address line 2
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_addressTwo}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_addressTwo"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Address 2"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        City
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_city}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="text"
                                            name="b_city"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="City"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Postcode / Zip
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_zipCode}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="number"
                                            name="b_zipCode"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="zipCode"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Country / Region
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"

                                            placeholder="Select"
                                            value={country}
                                            onChange={(selectOption) => {
                                                setCountry(selectOption)
                                                console.log("setCountry",selectOption)
                                                GetState(selectOption?.value).then((result) => {
                                                    const filter = result.map((ele) => ({
                                                        value: ele?.id,
                                                        label: ele?.name
                                                    }))
                                                    //console.log("filter",filter)
                                                    setBillStateOption(filter);
                                                });
                                            }}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={countries}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        State
                                    </label>
                                    <div>

                                        <Select
                                            classNamePrefix="select"
                                            placeholder="Select"
                                            value={state}

                                            onChange={(selectOption) => {
                                                setState(selectOption)

                                            }}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={billstateOption}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_email}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="email"
                                            name="b_email"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Email"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Phone
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_phone}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="number"
                                            name="b_phone"
                                            min="0"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Phone"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Method
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            //defaultValue={userTypes[0]}
                                            placeholder="Select"
                                            value={selectPaymentMethod}
                                            // // onBlur={handleBlur}
                                            onChange={(selectOption) => setSelectPaymentMethod(selectOption)}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={paymentMethod}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Transaction Id
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.b_transactionId}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            type="number"
                                            min="0"
                                            name="b_transactionId"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Transaction Id"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700 "></hr>
                    {/* {shipping address} */}
                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm">
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg text-black font-medium">Shipping Address</h2>
                            <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setShippingFlag((prev) => !prev)} /></div>
                        </div>

                        <div className={`${shippingFlag ? "flex flex-col gap-5 mt-5" : "hidden "}`}>
                            <div className="flex gap-5">
                                <h4 className="text-sm text-[#0094FF]" >Load Shipping Address</h4>
                                <h4 className="text-sm text-[#0094FF] cursor-pointer" onClick={() => copyBillingHandle()} >Copy Billing Address</h4>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3  gap-5 items-center">
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        First Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            // onBlur={handleBlur}
                                            type="text"
                                            name="firstName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="First Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Last Name
                                    </label>
                                    <div className="">
                                        <input
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            type="text"
                                            name="lastName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Last Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Company Name
                                    </label>
                                    <div className="">
                                        <input

                                            value={compnayName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            type="text"
                                            name="compnayName"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Company Name"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Address line 1
                                    </label>
                                    <div className="">
                                        <input
                                            value={addressOne}
                                            onChange={(e) => setAddressOne(e.target.value)}
                                            type="text"
                                            name="addressOne"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Address 1"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Address line 2
                                    </label>
                                    <div className="">
                                        <input
                                            value={addressTwo}
                                            onChange={(e) => setAddressTwo(e.target.value)}
                                            type="text"
                                            name="addressTwo"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Address 2"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        City
                                    </label>
                                    <div className="">
                                        <input
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            type="text"
                                            name="city"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="City"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Postcode / Zip
                                    </label>
                                    <div className="">
                                        <input
                                            value={zipCode}
                                            onChange={(e) => setZipCode(e.target.value)}
                                            type="number"
                                            name="zipCode"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="City"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Country / Region
                                    </label>
                                    <div>
                                        {/* <CountrySelect
                                            onChange={handleShipCountryChange}
                                            placeHolder="Select Country"
                                        /> */}
                                        <Select
                                            classNamePrefix="select"

                                            placeholder="Select"
                                            value={shipCountry}
                                            onChange={(selectOption) => {
                                                setShipCountry(selectOption)
                                                GetState(selectOption?.value).then((result) => {
                                                    const filter = result.map((ele) => ({
                                                        value: ele?.id,
                                                        label: ele?.name
                                                    }))
                                                    setShipStateOption(filter);
                                                });
                                            }}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={countries}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        State
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            placeholder="Select"
                                            value={shipState}

                                            onChange={(selectOption) => {
                                                setShipState(selectOption)

                                            }}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={shipStateOption}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div className="">
                                        <input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            name="email"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Email"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Phone
                                    </label>
                                    <div className="">
                                        <input
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            type="number"
                                            min="0"
                                            name="phone"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Phone"
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Method
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            //defaultValue={userTypes[0]}
                                            placeholder="Select"
                                            value={selectShipPayMethod}
                                            // // onBlur={handleBlur}
                                            onChange={(selectOption) => setSelectShipPayMethod(selectOption)}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={paymentMethod}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Transaction Id
                                    </label>
                                    <div className="">
                                        <input
                                            value={transactionId}
                                            onChange={(e) => setTransactionId(e.target.value)}
                                            type="number"
                                            min="0"
                                            name=" transactionId"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Transaction Id"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700 "></hr>
                    {/* {order details} */}
                    <div className="flex flex-col bg-white p-5 rounded-lg shadow-sm">
                        <label className="text-black text-lg font-medium">Order Details</label>
                        <div className="flex flex-col gap-5">
                            <label className="text-sm text-[#0094FF]">Use wallet balance</label>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 items-center">
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Add Product
                                    </label>
                                    <div>
                                        <Select
                                            classNamePrefix="select"
                                            //defaultValue={userTypes[0]}
                                            placeholder="Select"
                                            value={selectProduct}
                                            // // onBlur={handleBlur}
                                            onChange={(selectOption) => {
                                                console.log("selectOption", selectOption)
                                                setSelectProduct(selectOption)
                                                setSummaryDataList([selectOption])
                                            }}
                                            name="userStatus"
                                            className="basic-single sm:text-sm"
                                            options={productOption}
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Apply Coupon
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.coupon}
                                            onChange={(event) => {
                                                handleChange(event)
                                            }}
                                            // onBlur={handleBlur}

                                            type="text"
                                            name="coupon"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="Apply Coupon "
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700"></hr>

                    {/* {summary} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm ">
                        <label className="text-black text-lg font-medium">Summary</label>
                        <div>
                            <SummaryTable columns={summary_columns()} data={summaryDataList} />
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between  w-full mt-5 gap-5">
                            <div className="basis-1/5">
                                <div>
                                    <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                        Coupon
                                    </label>
                                    <div className="">
                                        <input
                                            value={values.coupon}
                                            readOnly
                                            // onChange={handleChange}
                                            // onBlur={handleBlur}
                                            type="text"
                                            name=""
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                            placeholder="coupon"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="basis-2/5 shadow-md border rounded-lg ">
                                <div className="flex flex-col  p-5 gap-x-5 gap-y-6 ">
                                    <div className="flex justify-between ">
                                        <h4 className="text-sm text-gray-500">Item SubTotal</h4>
                                        <span className="text-sm">{selectProduct?.p_product_price * selectProduct?.p_stok_quantity}</span>
                                    </div>
                                    {/* <div className="flex justify-between border-t-2 pt-4">
                                        <h4 className="text-sm text-gray-500">Coupons</h4>
                                        <span className="text-sm">2000</span>
                                    </div> */}
                                    <div className="flex justify-between border-t-2 pt-4">
                                        <h4 className="text-md text-black">Order Total</h4>
                                        <span className="text-md">{selectProduct?.p_product_price * selectProduct?.p_stok_quantity }</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700"></hr>
                    {/* {shipment tracking} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm ">
                        <div className="flex justify-between items-center">
                            <h2 className="text-md font-medium text-lg">Shipment Tracking</h2>
                            <div><ChevronDownIcon className="w-7 h-7 cursor-pointer" onClick={() => setShipmentTrackFlag((prev) => !prev)} /></div>
                        </div>
                        <div className={`${shipmentTrackFlag ? "grid grid-cols-1 md:grid-cols-3 gap-5 my-5" : "hidden "}`}>
                            <div>
                                <label className="flex  text-sm font-medium leading-6 text-gray-900">
                                    Tracking Number
                                </label>
                                <div className="">
                                    <input
                                        value={values.tracking_number}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        // onBlur={handleBlur}
                                        type="number"
                                        name="tracking_number"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                        placeholder="Tracking number"
                                    />

                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Shipping Provider
                                </label>
                                <div>
                                    <Select
                                        value={selectShipProvider}
                                        onChange={(selectOption) => setSelectShipProvider(selectOption)}
                                        className=" sm:text-sm"
                                        options={shippingProvider}
                                        styles={customStyles}
                                    />

                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Date Shipped
                                </label>
                                <div className="flex">
                                    <input
                                        type="date"
                                        value={values.tracking_ship_date}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        name="tracking_ship_date"
                                        className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78]"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Marked order as
                                </label>
                                <div className="flex items-center gap-5">
                                    {
                                        markOrder.map((ele) => {
                                            return (
                                                <>
                                                    <div className="flex gap-2 items-center">
                                                        <input type="radio"
                                                            checked={!!selectMarkOrder[ele.key]}
                                                            onChange={(e) => handleMarkOrder(ele.key, e.target.checked)}
                                                            className="rounded-full  text-[#a96a37]  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1  focus:ring-[#ebac78]" />
                                                        <span className="text-sm">{ele.label}</span>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }


                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700"></hr>
                    {/* {order action} */}
                    <div className="flex flex-col bg-white p-4 rounded-lg shadow-sm">
                        <label className="text-black text-lg font-medium">Order Actions</label>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 my-5">
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Choose Actions
                                </label>
                                <div>
                                    <Select
                                        classNamePrefix="select"
                                        defaultValue="select"
                                        placeholder="Select"
                                        value={selectOrderAction}
                                        // // onBlur={handleBlur}
                                        onChange={(selectOption) => setSelectOrderAction(selectOption)}

                                        className="basic-single sm:text-sm"
                                        options={orderAction}
                                        styles={customStyles}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="h-px my-5 bg-gray-300 border-0 dark:bg-gray-700"></hr>

                    <div className="flex gap-5 justify-end ">
                        <button
                            onClick={clearForm}
                            type="button"
                            className=" rounded-md border  text-[#3a4b43] bg-[#fff] shadow-sm   px-4 py-2 text-sm font-medium"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={()=>handleSubmit()}
                            type="button"
                            className=" rounded-md border  text-[#fff] bg-[#3a4b43] shadow-sm   px-4 py-2 text-sm font-medium"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>

            {
                loading &&
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Spinner />
                    </div>
                </div>

            }
        </>
    )
}

export default AddOrder